import React, { useEffect, useRef } from "react";
import { Wheel } from "spin-wheel";

function App() {
  const wheelContainerRef = useRef(null);

  useEffect(() => {
    // Define the wheel properties
    const props = {
      items: [
        { label: "Watch movie", backgroundColor: "#ffffff" },
        { label: "Hamoodi", backgroundColor: "#f9c7ff" },
        { label: "Hay Day", backgroundColor: "#eb5dfc" },
        { label: "Any wish ;)", backgroundColor: "#ffffff" },
        { label: "Watch show", backgroundColor: "#f9c7ff" },
        { label: "Extra Spin", backgroundColor: "#eb5dfc" },
        { label: "$100 gift card", backgroundColor: "#ffffff" },
        { label: "Kisses", backgroundColor: "#f9c7ff" },
        { label: "Hugs", backgroundColor: "#eb5dfc" },
      ],
      onRest: (event) => {
        const winningPrize = props.items[event.currentIndex].label;
        alert(`You won: ${winningPrize}`);
      },
    };
    

    // Initialize the wheel
    const wheel = new Wheel(wheelContainerRef.current, props);

    // Spin the wheel programmatically (optional)
    const spinWheel = () => {
      const duration = 4000; // Spin duration in milliseconds
      const easing = (n) => n * n; // Custom easing function (optional)
      wheel.spinToItem(Math.floor(Math.random() * props.items.length), duration, true, 2, 1, easing);
    };

    // Add a button to trigger the spin
    const button = document.getElementById("spinButton");
    button.addEventListener("click", spinWheel);

    return () => {
      wheel.remove(); // Cleanup when the component unmounts
    };
  }, []);



  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>HAPPY 22nd BIRTHDAY GRANDMA!</h1>
      <h1>Spinning Wheel Game (Amoura Edition)</h1>

      {/* Add a wheel container with an indicator */}
      <div style={{ position: "relative", width: "300px", height: "300px", margin: "0 auto" }}>
        {/* Indicator */}
        {/* Downward Pointing Arrow */}
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translateX(-50%) rotate(180)", // Rotate the triangle to point downward
            width: "20px",
            height: "20px",
            backgroundColor: "rgb(250, 59, 209)",
            clipPath: "polygon(50% 100%, 0 0, 100% 0)", // Downward triangle
          }}
        ></div>

        {/* Wheel */}
        <div
          ref={wheelContainerRef}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            border: "2px solid #333",
          }}
        ></div>
      </div>

      <button id="spinButton" style={{ marginTop: "20px", padding: "10px 20px", fontWeight: "bold" }}>
        Spin the Wheel
      </button>
    </div>
  );
}


//   return (
//     <div style={{ textAlign: "center", padding: "20px", backgroundColor: "f3c8f7" }}>
//       <h1>HAPPY 22nd BIRTHDAY GRANDMA!</h1>
//       <h1>Spinning Wheel Game (Amoura Edition)</h1>
//       <div
//         ref={wheelContainerRef}
//         style={{ width: "300px", height: "300px", margin: "0 auto" }}
//       />
//       <button id="spinButton" style={{ backgroundColor: "#ffffff", marginTop: "20px", padding: "10px 20px" }}>
//         Spin the Wheel
//       </button>
//     </div>
//   );
// }

export default App;
